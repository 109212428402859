exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-edgelink-js": () => import("./../../../src/pages/Edgelink.js" /* webpackChunkName: "component---src-pages-edgelink-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-js": () => import("./../../../src/pages/Life.js" /* webpackChunkName: "component---src-pages-life-js" */),
  "component---src-pages-maintain-js": () => import("./../../../src/pages/Maintain.js" /* webpackChunkName: "component---src-pages-maintain-js" */),
  "component---src-pages-perf-js": () => import("./../../../src/pages/Perf.js" /* webpackChunkName: "component---src-pages-perf-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

